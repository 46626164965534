import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../App";
import { CDN_URL } from "../Helpers";
import { STR_NO_COURSE_CONTENT, STR_YOU_MUST_TAKE_COURSE_BELOW } from "../Strings";
import OneCourseContent from '../ones/OneCourseContent';
import { MdWarning } from "react-icons/md";
import OneCourse from "../ones/OneCourse";

export default function View({ courseContents, courseId, requiredCourseData }) {
  const appContext = useContext(AppContext);

  /* Place states here */
  const [isRequiredCourseTaken, setIsRequiredCourseTaken] = useState(false);

  useEffect(() => {
    if (requiredCourseData && appContext.currentUserData) {
      const _coursesTaken = JSON.parse(appContext.currentUserData.courseCertificatesEarned);

      for (let i = 0; i < _coursesTaken.length; i++) {
        const _course = _coursesTaken[i];
        if (Number(_course.courseId) === Number(requiredCourseData.id)) {
          setIsRequiredCourseTaken(true);
          break;
        }
      }

    }
  }, [requiredCourseData, appContext.currentUserData])

  if (requiredCourseData) {
    if (isRequiredCourseTaken) {
      return (
        <div
          className="row"
        >
          {
            (courseContents && courseContents.length > 0) ?
              courseContents.map((item, i) => {
                return (
                  <div className="col-md-4" key={i}>
                    <OneCourseContent courseId={courseId} data={item} />
                  </div>
                )
              })
              :
              <div className="col-md-12">
                <h6
                  style={{
                    fontSize: "14px",
                  }}
                >
                  {STR_NO_COURSE_CONTENT[appContext.language]}
                </h6>
              </div>
          }
        </div>
      );
    } else {
      return (
        <div
          className="row"
        >
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <h6
                  style={{
                    fontSize: "18px",
                  }}
                  className="text-center text-secondary mb-4"
                >
                  <MdWarning size={70} /><br />
                  {STR_YOU_MUST_TAKE_COURSE_BELOW[appContext.language]}
                </h6>

                <OneCourse data={requiredCourseData} />
              </div>
            </div>
          </div>
        </div>
      );
    }
  } else {
    return (
      <div
        className="row"
      >
        {
          (courseContents && courseContents.length > 0) ?
            courseContents.map((item, i) => {
              return (
                <div className="col-md-4" key={i}>
                  <OneCourseContent courseId={courseId} data={item} />
                </div>
              )
            })
            :
            <div className="col-md-12">
              <h6
                style={{
                  fontSize: "14px",
                }}
              >
                {STR_NO_COURSE_CONTENT[appContext.language]}
              </h6>
            </div>
        }
      </div>
    );
  }
}
