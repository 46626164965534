import { useContext, useEffect, useState, useRef } from "react"
import { AppContext } from "../App"
import { AiOutlineUser } from 'react-icons/ai';
import { getInlineLoader } from "../Helpers";
import {
  STR_CHANGE_PHONE_NUMBER,
  STR_CONSULTATION_FEE_PAYMENTS,
  STR_MY_ORDERS,
  STR_MY_PURCHASES,
  STR_NO_RECORDS,
  STR_PAYMENT_SETTINGS,
  STR_PERSONAL_SETTINGS,
  STR_PURCHASED_BOOK,
  STR_SUBSCRIPTION_FEE_PAYMENTS,
  STR_UPDATE_PERSONAL_INFO,
  STR_UPDATE_USERNAME
} from "../Strings";
import { BsFillPersonLinesFill, BsPersonGear } from 'react-icons/bs';
import { MdInfoOutline, MdList, MdOutlinePayment, MdOutlinePayments, MdOutlinePhonelinkSetup, MdShoppingCart } from 'react-icons/md';
import OnePurchasedItem from '../ones/OnePurchasedItem';

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [myPurchases, setMyPurchases] = useState(null);

  async function init() {
    //run initializations here
    setReady(false);
    await appContext.getCurrentUserData();
    setReady(true);
  }



  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    if (appContext.currentUserData) {
      const _purchases = JSON.parse(appContext.currentUserData.purchasedItems);
      const _filtered = _purchases.filter((item) => {
        return (item.itemType === 'digital')
      })

      setMyPurchases(_filtered)
    }
  }, [appContext.currentUserData])

  if (ready) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          overflow: "scroll",
        }}
        className="mNoScrollBar"
      >
        <div className="container">

          <h1
            className="mSectionTitle"
          >
            {STR_PURCHASED_BOOK[appContext.language]}
          </h1>

          <div className="row">
            {
              (myPurchases && myPurchases.length > 0) ?
                myPurchases.reverse().map((item, i) => {
                  return (
                    <div className="col-md-12" key={i}>
                      <OnePurchasedItem data={item} />
                    </div>
                  )
                })
                :
                <div className="col-md-12">
                  <h6
                    style={{
                      fontSize: "14px",
                      padding: "10px"
                    }}
                  >
                    {STR_NO_RECORDS[appContext.language]}
                  </h6>
                </div>
            }
          </div>

        </div>


      </div>
    )
  } else {
    return (
      <div
        className="mSupportLoading"
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        {getInlineLoader()}
      </div>
    );
  }


}