import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../App";
import { CDN_URL } from "../Helpers";

export default function View({ data, courseId }) {
  const appContext = useContext(AppContext);

  /* Place states here */


  function view() {
    appContext.navTo({
      item: "view",
      subItem: "course-content",
      extraItem: courseId,
      moreItem: data.id,
    });
  }

  return (
    <div
      className="OneArticle small bg-surface"
      onClick={view}
    >

      <div
        className="cover"
        style={{
          backgroundImage: `url(${CDN_URL + data.cover})`
        }}
      >
      </div>

      <div
        className="title"
      >
        {data.title}
      </div>
    </div>
  );
}
