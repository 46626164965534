import { useContext, useEffect, useState, useRef } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, CDN_URL, formatMoney, formatMoneyShorten, remove255 } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import SubscriptionExpiredWidget from '../ones/SubscriptionExpiredWidget';
import ImageSlider from "../ones/ImageSlider";
import { STR__ORDER_STATUS_INQUIRY, STR_BUY_NOW, STR_CANCEL_ORDER, STR_ENTER_PHONE_NUMBER_FOR_PAYMENT, STR_FINALIZE_PAYMENT_CARD, STR_FINALIZE_PAYMENT_MOBILE, STR_INVALID_PAYMENT_METHOD, STR_INVALID_QUANTITY, STR_INVALID_SHIPPING_ADDRESS, STR_ORDER_NOTES, STR_ORDER_NOW, STR_ORDER_STATUS_CANCELLED, STR_ORDER_STATUS_DELIVERED, STR_ORDER_STATUS_PENDING, STR_ORDER_STATUS_SUCCESS, STR_ORDER_WAS_RECEIVED_WAIT_FOR_FEEDBACK, STR_PAY_NOW, STR_PAY_WITH_CARD, STR_PAY_WITH_MOBILE, STR_PAYMENT_METHOD, STR_PENDING_PAYMENT_TEXT_HELP, STR_PHONE_NUMBER, STR_PRICE, STR_QUANTITY, STR_SHIPPING_ADDRESS, STR_SUCCESSFUL } from "../Strings";
import MobileInterface from "../MobileInterface";
import { MdCheckCircle } from "react-icons/md";


export default function View({ id }) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [payWith, setPayWith] = useState("mobile_money");
  const [payPhone, setPayPhone] = useState(remove255(appContext.currentUserData.phone));
  const [opSuccessful, setOpSuccessful] = useState(false);


  async function init() {
    //run initializations here
    setReady(false);
    await getData();
    setReady(true);
  }


  async function getData() {
    await callApi("get-shopping-order-data", { id }).then(response => {
      if (response.status === 1) {
        setData(response.data);
      }
    })
  }

  async function pay() {
    if (!loading) {
      if ((payWith === 'mobile_money' && payPhone.trim().length > 0) || payWith === 'credit_card') {
        setLoading(true);
        await callApi("retry-shopping-order-payment", { id, payWith, payPhone, }).then(response => {
          if (response.status === 1) {
            setOpSuccessful(true)
            if (payWith === 'credit_card') {
              MobileInterface.openLink(response.payment_gateway_url);
            }
          } else {
            appContext.tellError(response.msg);
          }
        })
        setLoading(false);
      } else {
        appContext.tellError(STR_INVALID_PAYMENT_METHOD[appContext.language]);
      }
    }
  }

  async function cancel() {
    if (!loading) {
      setLoading(true);
      await callApi("cancel-shopping-order", { id, payWith, payPhone, }).then(response => {
        if (response.status === 1) {
          getData();
          appContext.getMyShoppingOrders();
          appContext.tellMessage(STR_SUCCESSFUL[appContext.language]);
        } else {
          appContext.tellError(response.msg);
        }
      })
      setLoading(false);
    }
  }

  useEffect(() => {
    init();
  }, [id])


  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading])

  if (ready && data && Number(data.userId) === Number(appContext.currentUserData.id)) {
    return (
      <div className="container">

        <div className="row">

          <div className="col-md-12">
            <h6
              className="mSectionTitle"
              style={{
                borderBottom: "1px solid var(--primaryColor)",
                paddingBottom: "20px",
              }}
            >
              <span
                className="font-bold text-secondary"
                style={{
                  fontSize: "14px",
                  display: "block"
                }}
              >
                <span className="font-bold" style={{ fontSize: "32px" }}>{formatMoney(data.overallCost)}</span> TZS
              </span>

              {data.itemTitle}

              <span
                className="font-semi-bold text-muted"
                style={{
                  fontSize: "14px",
                  display: "block"
                }}
              >
                <span className="font-bold" style={{ fontSize: "20px" }}>{data.quantity}</span> {data.unit}
              </span>

              {(data.status === 'success') ? <span className="badge bg-info" style={{ fontSize: "12px" }}>{STR_ORDER_STATUS_SUCCESS[appContext.language]}</span> : ""}
              {(data.status === 'inquiry') ? <span className="badge bg-dark" style={{ fontSize: "12px" }}>{STR__ORDER_STATUS_INQUIRY[appContext.language]}</span> : ""}
              {(data.status === 'pending') ? <span className="badge bg-warning" style={{ fontSize: "12px" }}>{STR_ORDER_STATUS_PENDING[appContext.language]}</span> : ""}
              {(data.status === 'delivered') ? <span className="badge bg-success" style={{ fontSize: "12px" }}>{STR_ORDER_STATUS_DELIVERED[appContext.language]}</span> : ""}
              {(data.status === 'cancelled') ? <span className="badge bg-danger" style={{ fontSize: "12px" }}>{STR_ORDER_STATUS_CANCELLED[appContext.language]}</span> : ""}

            </h6>

            {
              (data.shippingAddress) ?
                <div
                  className="mb-4 font-light"
                  style={{
                    fontSize: "18px",
                  }}
                >
                  <label className="form-label">{STR_SHIPPING_ADDRESS[appContext.language]}</label><br />
                  {data.shippingAddress}
                </div>
                : ""
            }

            {
              (data.notes) ?
                <div
                  className="mb-4 font-light"
                  style={{
                    fontSize: "18px",
                    borderLeft: "6px solid var(--secondaryColor)",
                    paddingLeft: "10px",
                  }}
                >
                  {data.notes}
                </div>
                : ""
            }
          </div>

          {
            (data.status === 'pending') ?
              <div className="col-md-12">
                <div
                  style={{
                    maxWidth: "400px",
                    margin: "20px auto",
                  }}
                >
                  <div className="card">
                    <div className="card-body">
                      {
                        (opSuccessful) ?
                          <div className="mt-4 mb-4 text-center" style={{ width: "100%", padding: "40px" }}>

                            <h1
                              style={{
                                fontSize: "20px",
                              }}
                            >
                              <MdCheckCircle size={70} className="text-secondary" /> <br />
                              {(payWith === 'mobile_money') ? STR_FINALIZE_PAYMENT_MOBILE[appContext.language] : STR_FINALIZE_PAYMENT_CARD[appContext.language]}
                            </h1>
                          </div>
                          :
                          <>
                            <div className="row">

                              <div className="col-12 mb-4 mt-4">
                                <h6
                                  className="font-semi-bold text-secondary text-center"
                                  style={{
                                    fontSize: "18px",
                                  }}
                                >
                                  {STR_PENDING_PAYMENT_TEXT_HELP[appContext.language]}
                                </h6>
                              </div>


                              <div className="col-12 mb-2 mt-2">
                                <label className="form-label">{STR_PRICE[appContext.language]} (TZS)</label>
                                <h6
                                  style={{
                                    fontSize: "32px",
                                  }}
                                >
                                  {formatMoney(data.overallCost)}
                                </h6>
                              </div>

                              <div className="col-12">
                                <div className="mt-2 mb-2">
                                  <label className="form-label">{STR_PAYMENT_METHOD[appContext.language]}</label>
                                  <select
                                    className="form-control"
                                    value={payWith}
                                    onChange={(e) => setPayWith(e.target.value)}
                                    style={{
                                      border: "1px solid var(--secondaryColor)",
                                    }}
                                  >
                                    <option value="credit_card">{STR_PAY_WITH_CARD[appContext.language]}</option>
                                    <option value="mobile_money">{STR_PAY_WITH_MOBILE[appContext.language]}</option>
                                  </select>
                                </div>

                                {
                                  (payWith === 'mobile_money') ?
                                    <div className="mt-2 mb-2">
                                      <label className="form-label">{STR_PHONE_NUMBER[appContext.language]}</label>
                                      <input
                                        className="form-control"
                                        value={payPhone}
                                        onChange={(e) => setPayPhone(e.target.value)}
                                        style={{
                                          border: "1px solid var(--secondaryColor)",
                                        }}
                                      />
                                      <div className="form-text">{STR_ENTER_PHONE_NUMBER_FOR_PAYMENT[appContext.language]}</div>
                                    </div>
                                    : ""
                                }
                              </div>

                              <div className="col-12 mb-4 mt-4 text-center">
                                <button
                                  className="btn btn-padded btn-rounded btn-primary btn-block"
                                  onClick={pay}
                                >
                                  {STR_PAY_NOW[appContext.language]}
                                </button>

                                <h6 className="m-3">OR</h6>

                                <button
                                  className="btn btn-padded btn-rounded btn-danger btn-block"
                                  onClick={() => {
                                    appContext.activateDialog({
                                      message: STR_CANCEL_ORDER[appContext.language] + "?",
                                      onConfirm: cancel,
                                    })
                                  }}
                                >
                                  {STR_CANCEL_ORDER[appContext.language]}
                                </button>
                              </div>

                            </div>
                          </>
                      }

                    </div>
                  </div>
                </div>
              </div>
              : ""
          }

        </div>
      </div>
    )
  } else {
    return (
      <div
        className="mSupportLoading container"
      >
        {getInlineLoader()}
      </div>
    );
  }


}