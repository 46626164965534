import React from 'react';

export default function ImageSlider({ images }) {
  return (
    <div id="carouselBanner" className="carousel slide" data-bs-ride="carousel" data-bs-interval="5000" style={{ height: "100%", width: "100%" }}>
      <div className="carousel-inner" style={{ height: "100%", width: "100%" }}>


        {
          images.map((item, i) => {
            return (
              <img
                src={item}
                key={i}
                style={{
                  objectFit: "cover",
                  width: "100%",
                  height: "var(--imageSliderHeight)",
                  objectPosition: "center",
                }}
                className="carousel-item active"
              />
            )
          })
        }

      </div>

      {
        (images.length > 1) ?
          <>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselBanner" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselBanner" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </>
          : ""
      }
    </div>
  )
}
