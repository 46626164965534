export const STR_APP_NAME = {
  en: "Chuo cha Mauzo",
  sw: "Chuo cha Mauzo",
};

export const STR_HEALTH = {
  en: "health",
  sw: "afya",
}

export const STR_RELATIONSHIPS = {
  en: "relationships",
  sw: "mahusiano",
}

export const STR_STORIES = {
  en: "stories",
  sw: "masimulizi",
}

export const STR_COURSES = {
  en: "Courses",
  sw: "Kozi",
}


export const STR_GET_STARTED = {
  en: "get started",
  sw: "anza sasa",
}

export const STR_CONTINUE = {
  en: "continue",
  sw: "endelea",
}

export const STR_ENTER = {
  en: "enter",
  sw: "ingia",
}

export const STR_ENTER_YOUR_PHONE_NUMBER = {
  en: "Enter your phone number",
  sw: "Ingiza namba yako ya simu",
}

export const STR_PHONE_NUMBER = {
  en: "Phone number",
  sw: "Namba ya simu",
}

export const STR_ENTER_OTP = {
  en: "Enter the OTP sent to your phone",
  sw: "Ingiza OTP uliotumiwa kwenye simu",
}

export const STR_PLEASE_WAIT = {
  en: "Please wait..",
  sw: "Tafadhali subiri..",
}

export const STR_INVALID_PHONE = {
  en: "Invalid phone number",
  sw: "Namba ya simu sio sahihi",
}

export const STR_INVALID_OTP = {
  en: "Invalid OTP",
  sw: "OTP sio sahihi",
}

export const STR_WELCOME = {
  en: "Welcome..",
  sw: "Karibu..",
}

export const STR_CHANGE_PHONE = {
  en: "Change Phone",
  sw: "Badili Simu",
}

export const STR_RESEND_OTP = {
  en: "Resend OTP",
  sw: "Tuma tena OTP",
}

export const STR_MENU_HOME = {
  en: "Home",
  sw: "Nyumbani",
}

export const STR_MENU_DATING = {
  en: "Dating",
  sw: "Mahusiano",
}

export const STR_MENU_DOCTORS = {
  en: "Doctors",
  sw: "Madaktari",
}

export const STR_MENU_INBOX = {
  en: "Inbox",
  sw: "Ujumbe",
}

export const STR_MENU_ARTICLES = {
  en: "Articles",
  sw: "Makala",
}

export const STR_MENU_STORIES = {
  en: "Stories",
  sw: "Simulizi",
}

export const STR_MENU_UTILITIES = {
  en: "Utilities",
  sw: "Visaidizi",
}

export const STR_LATEST_ARTICLES = {
  en: "Latest Articles",
  sw: "Makala Mpya",
}

export const STR_MORE_ARTICLES = {
  en: "More Articles",
  sw: "Makala Zaidi",
}

export const STR_LATEST_STORIES = {
  en: "Latest Stories",
  sw: "Simulizi Mpya",
}

export const STR_LATEST_COURSES = {
  en: "Latest Courses",
  sw: "Kozi Mpya",
}

export const STR_MORE_STORIES = {
  en: "More Stories",
  sw: "Simulizi Zaidi",
}

export const STR_MORE_COURSES = {
  en: "More Courses",
  sw: "Kozi Zaidi",
}

export const STR_PERSONAL_SETTINGS = {
  en: "Personal Settings",
  sw: "Mipangilio Binafsi",
}

export const STR_UPDATE_USERNAME = {
  en: "Update username",
  sw: "Badili/Weka jina",
}

export const STR_CHANGE_PHONE_NUMBER = {
  en: "Change phone number",
  sw: "Badili namba ya simu",
}

export const STR_UPDATE_PERSONAL_INFO = {
  en: "Update personal information",
  sw: "Badili/Weka taarifa binafsi",
}

export const STR_PAYMENT_SETTINGS = {
  en: "Payments and Purchases",
  sw: "Malipo na Manunuzi",
}

export const STR_SUBSCRIPTION_FEE_PAYMENTS = {
  en: "My Subscription",
  sw: "Kifurushi cha matumizi",
}

export const STR_CONSULTATION_FEE_PAYMENTS = {
  en: "Doctor's consultation fee payment",
  sw: "Malipo ya kuwasiliana na daktari",
}

export const STR_MY_SUBSCRIPTION = {
  en: "My Subscription",
  sw: "Kifurushi cha matumizi",
}

export const STR_SUBSCRIPTION_HAS_EXPIRED = {
  en: "Subscription has expired",
  sw: "Kifurushi kimeisha",
}

export const STR_PURCHASE_SUBSCRIPTION_PACKAGE = {
  en: "Purchase a subscription package to view all content in this App",
  sw: "Nunua kifurushi cha matumizi ili kuona kila kitu kilichomo ndani ya App",
}

export const STR_SELECT = {
  en: "Select",
  sw: "Chagua",
}

export const STR_COMPLETE_PAYMENT = {
  en: "Complete Payment",
  sw: "Kamilisha Malipo",
}

export const STR_PAY_WITH_CARD = {
  en: "Pay with Credit Card",
  sw: "Lipia kwa Kadi",
}

export const STR_PAY_WITH_MOBILE = {
  en: "Pay with Mobile Network",
  sw: "Lipia kwa Mtandao wa Simu",
}

export const STR_SELECT_PAYMENT_METHOD = {
  en: "Select payment method",
  sw: "Chagua njia ya kufanya malipo",
}

export const STR_ENTER_PHONE_NUMBER_FOR_PAYMENT = {
  en: "Enter phone number you want to use for this payment. All Tanzanian networks are supported.",
  sw: "Ingiza namba ya simu utakayofanyia malipo. Tunapokea kutoka mitandao yote ya Tanzania",
}

export const STR_FINALIZE_PAYMENT_CARD = {
  en: "Please wait, you'll soon be redirected to complete the payment with your card.",
  sw: "Tafadhali subiri utaelekezwa kwenye kiungo cha kufanyia malipo kwa kadi yako",
}

export const STR_FINALIZE_PAYMENT_MOBILE = {
  en: "Push USSD was sent to your phone please check and finalize the payment",
  sw: "Tafadhali angalia simu yako ili kukamilisha malipo kwa USSD Push",
}

export const STR_BUY_NOW = {
  en: "Buy Now",
  sw: "Nunua Sasa",
}

export const STR_DAYS = {
  en: "Days",
  sw: "Siku",
}

export const STR_FROM = {
  en: "From",
  sw: "Kuanzia",
}

export const STR_TO = {
  en: "To",
  sw: "Hadi",
}

export const STR_PRICE = {
  en: "Price",
  sw: "Bei",
}

export const STR_PASSWORD = {
  en: "Password",
  sw: "Nenosiri",
}

export const STR_CONFIRM = {
  en: "Confirm",
  sw: "Hakiki",
}

export const STR_INVALID_PASSWORD = {
  en: "Invalid Password, password must have atleast 8 characters",
  sw: "Nenosiri angalau liwe na muunganiko wa namba na herufi angalau 8",
}

export const STR_INVALID_CONFIRM_PASSWORD = {
  en: "Please confirm your password correctly",
  sw: "Tafadhali hakiki nenosiri lako kwa usahihi",
}

export const STR_CHANGE_LANGUAGE = {
  en: "Tumia Swahili",
  sw: "Change to English",
}

export const STR_FORGOT_PASSWORD = {
  en: "Forgot Password",
  sw: "Nimesahau Nenosiri",
}

export const STR_NO = {
  en: "No",
  sw: "Hapana",
}

export const STR_YES = {
  en: "Yes",
  sw: "Ndio",
}

export const STR_BOND_CALCULATOR = {
  en: "Bonds Calculator",
  sw: "Kikokotozi cha Hatifungani",
}

export const STR_LOAN_CALCULATOR = {
  en: "Loans Calculator",
  sw: "Kikokotozi cha Mkopo",
}

export const STR_UTT_CALCULATOR = {
  en: "UTT Investment Calculator",
  sw: "Kikokotozi cha uwekezaji wa UTT",
}

export const STR_BOOKS_LIBRARY = {
  en: "Books' Library",
  sw: "Maktaba ya Vitabu",
}

export const STR_NO_COURSE_CONTENT = {
  en: "Content for this course will be published soon",
  sw: "Makala kwenye kozi hii zitawekwa hivi punde",
}

export const STR_TAKE_QUIZ = {
  en: "Take Quiz",
  sw: "Fanya Jaribio",
}

export const STR_SELECT_CORRECT_ANSWER = {
  en: "Select correct answer",
  sw: "Chagua jibu sahihi",
}

export const STR_WRITE_CORRECT_ANSWER = {
  en: "Write correct answer",
  sw: "Andika jibu sahihi",
}

export const STR_SUBMIT = {
  en: "Submit",
  sw: "Wasilisha",
}

export const STR_SUCCESSFUL = {
  en: "Successful....",
  sw: "Imefanikiwa...",
}

export const STR_YOUR_MARKS_WILL_APPEAR_HERE_AFTER_COMPLETING_QUIZ = {
  en: "Your marks will appear here after quiz completion",
  sw: "Alama zako zitaonekana hapa ukimaliza kufanya jaribio",
}

export const STR_YOUR_RESULTS = {
  en: "Your results",
  sw: "Matokeo Yako",
}

export const STR_TAKE_QUIZES_TO_BE_CERTIFIED = {
  en: "Take Quizes to become certified once you complete this course if you score beyond the average passmark",
  sw: "Fanya majaribio na upewe cheti mwisho wa kozi endapo utavuka wastani wa alama zinazotakiwa",
}

export const STR_REQUIRED_AVERAGE_PASSMARK = {
  en: "Required Average Passmark",
  sw: "Wastani wa Alama Unaotakiwa",
}

export const STR_SHOP = {
  en: "Shop",
  sw: "Duka",
}

export const STR_NO_ITEMS_FOUND = {
  en: "No items were found",
  sw: "Hakuna kitu kwa sasa",
}

export const STR_QUANTITY = {
  en: "Quantity",
  sw: "Kiasi",
}

export const STR_PAYMENT_METHOD = {
  en: "Payment Method",
  sw: "Njia ya malipo",
}

export const STR_INVALID_QUANTITY = {
  en: "Invalid Quantity",
  sw: "Kiasi sio sahihi",
}

export const STR_INVALID_PAYMENT_METHOD = {
  en: "Invalid Payment method or Phone number",
  sw: "Njia ya malipo au namba ya simu sio sahihi",
}

export const STR_ORDER_WAS_RECEIVED_WAIT_FOR_FEEDBACK = {
  en: "Your order was received, we'll get back to you soon",
  sw: "Oda yako imepokelewa, tutakurudia hivi punde",
}

export const STR_SHIPPING_ADDRESS = {
  en: "Shipping Address",
  sw: "Anwani/Mahali pa kutuma mzigo",
}

export const STR_INVALID_SHIPPING_ADDRESS = {
  en: "Invalid Shipping Address",
  sw: "Anwani/Mahali pa kutuma mzigo sio sahihi",
}

export const STR_ORDER_NOW = {
  en: "Order Now",
  sw: "Weka Oda Sasa",
}

export const STR_ORDER_NOTES = {
  en: "Would you like to leave any notes on this order? Write here",
  sw: "Je ungependa kuacha maelekezo yoyote? Andika hapa.",
}

export const STR_MY_ORDERS = {
  en: "My Orders",
  sw: "Oda zangu",
}

export const STR_MY_PURCHASES = {
  en: "My Purchases",
  sw: "Manunuzi yangu",
}

export const STR__ORDER_STATUS_INQUIRY = {
  en: "Inquiry",
  sw: "Inasubiri kuhakikiwa",
}

export const STR_ORDER_STATUS_PENDING = {
  en: "Pending payment",
  sw: "Inasubiri malipo",
}

export const STR_ORDER_STATUS_SUCCESS = {
  en: "Completed Payment",
  sw: "Malipo yamekamilika",
}

export const STR_ORDER_STATUS_DELIVERED = {
  en: "Delivered",
  sw: "Imekamilishwa",
}

export const STR_ORDER_STATUS_CANCELLED = {
  en: "Cancelled",
  sw: "Imesitishwa",
}

export const STR_PAY_NOW = {
  en: "Pay Now",
  sw: "Lipia Sasa",
}

export const STR_PENDING_PAYMENT_TEXT_HELP = {
  en: "Payment was not received for this order, you can retry to make payment or contact us if you have already made the payment but still see this message",
  sw: "Malipo ya Oda hii hayajakamilika. Unaweza kurudia kufanya malipo au wasiliana nasi kama uifanya malipo lakini bado unaona ujumbe huu",
}

export const STR_CANCEL_ORDER = {
  en: "Cancel Order",
  sw: "Sitisha Oda",
}

export const STR_NO_RECORDS = {
  en: "No records were found",
  sw: "Hakuna kitu kwa sasa",
}

export const STR_PURCHASED_BOOK = {
  en: "Purchased Books",
  sw: "Vitabu Nilivyonunua",
}

export const STR_OTHER_PURCHASES = {
  en: "Other Purchases",
  sw: "Manunuzi Mengineyo",
}

export const STR_OPEN = {
  en: "Open",
  sw: "Fungua",
}

export const STR_DOWNLOAD = {
  en: "Download",
  sw: "Pakua",
}

export const STR_CERTIFICATE_PROGRAMS = {
  en: "Certificate Programs",
  sw: "Programu za Vyeti",
}

export const STR_COURSES_IN_THIS_PROGRAM = {
  en: "Courses in this program",
  sw: "Kozi ndani ya programu hii",
}

export const STR_YOU_MUST_TAKE_COURSE_BELOW = {
  en: "You must complete the course below before proceeding with this one",
  sw: "Unatakiwa kumaliza kozi hio hapo chini kabla ya kuendelea na hii",
}

export const STR_COURSE_CERTIFICATES = {
  en: "Short Course Certificates",
  sw: "Vyeti vya kozi fupi",
}

export const STR_PROGRAM_CERTIFICATES = {
  en: "Program Certificates",
  sw: "Vyeti vya program mbalimbali",
}

export const STR_CERTIFICATE = {
  en: "Certificate",
  sw: "Cheti",
}

export const STR_FULLNAME = {
  en: "Fullname",
  sw: "Jina kamili",
}

export const STR_SUCCESS = {
  en: "Successful...",
  sw: "Imefanikiwa...",
}

export const STR_INVALID_USERNAME = {
  en: "Invalid name",
  sw: "Jina sio sahihi",
}

export const STR_PLEASE_UPDATE_FULLNAME_FOR_CERTIFICATES = {
  en: "Please update your fullname as you want it to appear on the certificates",
  sw: "Tafadhali hariri/weka jina lako kamili kama utakavyotaka lionekane kwenye vyeti",
}

export const STR_EDIT_NOW = {
  en: "Edit Now",
  sw: "Hariri Sasa",
}

export const STR_NOTIFICATIONS = {
  en: "Notifications",
  sw: "Taarifa",
}

export const STR_LOGOUT = {
  en: "Log Out",
  sw: "Ondoka kwenye Akaunti",
}

export const STR_SEARCH = {
  en: "Search",
  sw: "Tafuta",
}

export const STR_SEARCH_ARTICLES = {
  en: "Search Articles",
  sw: "Tafuta Makala",
}

export const STR_SEARCH_COURSES = {
  en: "Search Courses",
  sw: "Tafuta Kozi",
}

export const STR_ = {
  en: "",
  sw: "",
}