import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../App";
import { callApi, CDN_URL } from "../Helpers";
import { STR_BUY_NOW, STR_FEMALE, STR_FULLNAME, STR_GENDER, STR_INVALID_GENDER, STR_INVALID_USERNAME, STR_MALE, STR_PURCHASE_SUBSCRIPTION_PACKAGE, STR_SUBMIT, STR_SUBSCRIPTION_HAS_EXPIRED, STR_SUCCESS, STR_UPDATE_PERSONAL_INFO, STR_USERNAME } from "../Strings";
import { MdOutlineWarning, MdWarning } from "react-icons/md";

export default function View({ title }) {
  const appContext = useContext(AppContext);

  /* Place states here */
  const [fullname, setFullname] = useState(appContext.currentUserData?.fullname || "");
  const [loading, setLoading] = useState(false);

  async function submit() {
    if (fullname && fullname.trim().length > 0) {
      setLoading(true);
      await callApi("update-fullname", { fullname }).then(async response => {
        if (response.status === 1) {
          appContext.getCurrentUserData();
          appContext.tellMessage(STR_SUCCESS[appContext.language]);
          appContext.navBack();
        } else {
          appContext.tellError(response.msg);
        }
      })
      setLoading(false);
    } else {
      appContext.tellError(STR_INVALID_USERNAME[appContext.language])
    }
  }

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading])

  return (
    <div
      className="card"

    >
      <div className="card-body">

        <div className="mb-2">
          <label className="form-label">{STR_FULLNAME[appContext.language]}</label>
          <input
            className="form-control"
            onChange={(e) => {
              if (e.target.value.length <= 70) {
                setFullname(e.target.value)
              }
            }}
            value={fullname}
            maxLength={70}
          />
          <div className="form-text">{fullname.length}/70</div>
        </div>

        <div className="text-end">
          <button
            className="btn btn-rounded btn-padded btn-secondary mt-4 mb-4"
            onClick={submit}
          >
            {STR_SUBMIT[appContext.language]}
          </button>
        </div>
      </div>
    </div>
  );
}
