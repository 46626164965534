import React, { useContext, useState } from 'react';
import { STR_CERTIFICATE } from '../Strings';
import { AppContext } from '../App';
import { formatDate } from '../Helpers';

const Certificate = ({ courseTitle, studentName, date }) => {
  // State for dynamic fields
  const appContext = useContext(AppContext);

  return (
    <div style={{ textAlign: 'center' }}>

      {/* SVG Certificate */}
      <svg
        width="100%" height="80%" viewBox="0 0 1122.4371 794.06628"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* Static part of your SVG (adjusted according to your SVG design) */}
        <rect width="100%" height="100%" fill="#f4f4f4" stroke="black" strokeWidth="2" />

        {/* Dynamic content */}
        <text
          x="50%" y="30%"
          fontSize="48"
          textAnchor="middle"
          fontWeight="bold"
          fill="black"
        >
          {courseTitle}
        </text>

        <text
          x="50%" y="50%"
          fontSize="40"
          textAnchor="middle"
          fill="black"
        >
          {studentName}
        </text>

        <text
          x="50%" y="70%"
          fontSize="30"
          textAnchor="middle"
          fill="black"
        >
          {formatDate(date).substring(0, 10)}
        </text>

        {/* You can add more static/dynamic SVG elements here */}
      </svg>


    </div>
  );
};

export default Certificate;
