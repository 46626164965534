import { useContext, useEffect, useState, useRef } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, CDN_URL, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import SubscriptionExpiredWidget from '../ones/SubscriptionExpiredWidget';
import { MdPerson } from "react-icons/md";


export default function View({ id }) {

	const appContext = useContext(AppContext);

	/* Place states here */
	const [ready, setReady] = useState(false);
	const [data, setData] = useState(null);


	async function init() {
		//run initializations here
		setReady(false);
		await getData();
		setReady(true);
	}

	async function getData() {
		await callApi("get-article-data", { id }).then(response => {
			if (response.status === 1) {
				setData(response.data);
			}
		})
	}

	useEffect(() => {
		init();
	}, [id])



	if (ready && data) {
		return (
			<div className="container">
				<div className="row">

					<div className="col-md-12">
						<img
							style={{
								width: "100%",
								height: "auto",
							}}
							className="bg-surface"
							src={CDN_URL + data.cover}
							alt="Cover Image"
						/>
					</div>

					<div className="col-md-12">
						<h6
							className="mSectionTitle"
							style={{
								borderBottom: "1px solid var(--primaryColor)",
								paddingBottom: "20px",
							}}
						>
							{data.title}
							<span
								className="d-flex mt-3 font-bold text-secondary"
								style={{
									display: "block",
									fontSize: "16px",
								}}
							>
								<MdPerson size={28} className="align-self-center" />
								<span className="align-self-center">{data.authorName}</span>
							</span>

							<br />
							{
								JSON.parse(data.categories).map((item, i) => {
									return (
										<span
											key={i}
											style={{
												fontSize: "12px",
												marginRight: "5px",
												marginBottom: "5px",
											}}
											className="badge bg-dark"
										>
											{appContext.getContentCategoryTitle(item)}
										</span>
									)
								})
							}
						</h6>
					</div>

					{
						(data.isPaid === 'yes') ?
							(appContext.currentUserData.subscriptionStatus === 'active') ?
								<div
									className="mt-4 mb-4 col-md-12"
								>
									<div
										className=""
										style={{
											width: "100%",
											padding: "0",
											overflow: "hidden",
										}}
										dangerouslySetInnerHTML={{ __html: data.content }}
									>

									</div>
								</div>
								:
								<div
									className="mt-4 mb-4 col-md-12"
								>
									<SubscriptionExpiredWidget />
								</div>
							:
							<div
								className="mt-4 mb-4 col-md-12"
							>
								<div
									className=""
									style={{
										width: "100%",
										padding: "0",
										overflow: "hidden",
									}}
									dangerouslySetInnerHTML={{ __html: data.content }}
								>

								</div>
							</div>
					}


				</div>
			</div>
		)
	} else {
		return (
			<div
				className="mSupportLoading container"
			>
				{getInlineLoader()}
			</div>
		);
	}


}