import { useContext, useEffect, useState, useRef } from "react"
import { AppContext } from "../App"
import { callApi, formatMoney, getInlineLoader, remove255 } from "../Helpers";
import { STR_COMPLETE_PAYMENT, STR_DAYS, STR_ENTER_PHONE_NUMBER_FOR_PAYMENT, STR_FINALIZE_PAYMENT_CARD, STR_FINALIZE_PAYMENT_MOBILE, STR_INVALID_PAYMENT_METHOD, STR_PAY_WITH_CARD, STR_PAY_WITH_MOBILE, STR_PHONE_NUMBER, STR_SELECT_PAYMENT_METHOD } from "../Strings";
import { MdCheckCircle } from "react-icons/md";
import MobileInterface from "../MobileInterface";


export default function View({ id }) {

	const appContext = useContext(AppContext);

	/* Place states here */
	const [ready, setReady] = useState(false);
	const [data, setData] = useState(null);
	const [payWith, setPayWith] = useState('');
	const [payPhone, setPayPhone] = useState(remove255(appContext.currentUserData.phone));
	const [loading, setLoading] = useState(false);
	const [opSuccessful, setOpSuccessful] = useState(false);


	async function init() {
		//run initializations here
		setReady(false);
		await getData();
		setReady(true);
	}

	async function getData() {
		await callApi("get-subscription-package-data", { id }).then(response => {
			if (response.status === 1) {
				setData(response.data);
			}
		})
	}

	async function startSubscriptionOrder() {
		if (!loading) {
			if (payWith.trim().length > 0) {
				if (payWith === 'credit_card' || (payWith === 'mobile_money' && payPhone.trim().length === 10)) {
					setLoading(true);
					await callApi('create-subscription-order', { payWith, payPhone, packageId: id }).then(response => {
						if (response.status === 1) {
							setOpSuccessful(true)
							if (payWith === 'credit_card') {
								MobileInterface.openLink(response.payment_gateway_url);
							}
						} else {
							appContext.tellError(response.msg)
						}
					})
					setLoading(false)
				} else {
					appContext.tellError(STR_INVALID_PAYMENT_METHOD[appContext.language]);
				}
			} else {
				appContext.tellError(STR_INVALID_PAYMENT_METHOD[appContext.language]);
			}
		} else {
			appContext.tellInfo("Please wait....");
		}
	}

	useEffect(() => {
		init();
	}, [id])

	useEffect(() => {
		appContext.setShowOverlayLoader(loading);
	}, [loading])

	if (ready && data) {
		return (
			<div className="container">
				<div className="row">
					{
						(opSuccessful) ?
							<div className="col-md-12 mt-4 mb-4 text-center" style={{ padding: "40px" }}>

								<h1
									style={{
										fontSize: "20px",
									}}
								>
									<MdCheckCircle size={70} className="text-secondary" /> <br />
									{(payWith === 'mobile_money') ? STR_FINALIZE_PAYMENT_MOBILE[appContext.language] : STR_FINALIZE_PAYMENT_CARD[appContext.language]}
								</h1>
							</div>
							:
							<>
								<div className="col-md-12 mt-4 mb-4">

									<div className="row">
										<div className="col-6">
											<h6
												style={{
													fontSize: "24px",
												}}
											>
												<span className="font-semi-bold" style={{ fontSize: "16px", display: "block" }}>{data.title}</span>
												{formatMoney(data.duration)}
												<span style={{ fontSize: "14px", display: "block" }}>{STR_DAYS[appContext.language]}</span>
											</h6>
										</div>

										<div className="col-6">
											<h6
												style={{
													fontSize: "40px",
												}}
											>
												{formatMoney(data.price)}
												<span style={{ fontSize: "18px", display: "block" }}>TZS</span>
											</h6>
										</div>


									</div>

								</div>
								<div className="col-md-12">
									<div className="row">
										<div className="col-6">
											<button
												className={(payWith === 'mobile_money') ? "btn btn-block btn-padded btn-secondary" : "btn btn-block btn-padded btn-outline-secondary"}
												style={{
													height: "100%",
												}}
												onClick={(e) => setPayWith('mobile_money')}
											>
												{STR_PAY_WITH_MOBILE[appContext.language]}
											</button>
										</div>

										<div className="col-6">
											<button
												className={(payWith === 'credit_card') ? "btn btn-block btn-padded btn-secondary" : "btn btn-block btn-padded btn-outline-secondary"}
												style={{
													height: "100%",
												}}
												onClick={(e) => setPayWith('credit_card')}
											>
												{STR_PAY_WITH_CARD[appContext.language]}
											</button>
										</div>

									</div>
								</div>

								{
									(payWith) ?
										<div className="col-md-12">

											{
												(payWith === 'mobile_money') ?
													<div className="mt-3 mb-3">
														<label className="form-label">{STR_PHONE_NUMBER[appContext.language]}</label>
														<input
															className="form-control"
															maxLength={10}
															value={payPhone}
															onChange={(e) => setPayPhone(e.target.value)}
														/>
														<div className="form-text">
															{STR_ENTER_PHONE_NUMBER_FOR_PAYMENT[appContext.language]}
														</div>
													</div>
													: ""
											}

											<button
												className="btn btn-padded btn-block btn-rounded btn-primary mb-4 mt-4"
												onClick={startSubscriptionOrder}
											>
												{STR_COMPLETE_PAYMENT[appContext.language]}
											</button>
										</div>

										: ""
								}

							</>
					}

				</div>
			</div>
		)
	} else {
		return (
			<div
				className="mSupportLoading container"
			>
				{getInlineLoader()}
			</div>
		);
	}


}