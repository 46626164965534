import { useContext, useEffect, useState, useRef } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import {
  STR_LATEST_COURSES,
  STR_MORE_COURSES,
  STR_NO_ITEMS_FOUND,
  STR_SHOP,
} from "../Strings";
import OneShopItem from '../ones/OneShopItem';

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);

  async function init() {
    //run initializations here
    setReady(false);
    await appContext.getShopFeed();
    setReady(true);
  }


  useEffect(() => {
    init();
  }, [])

  useEffect(() => {

  }, [appContext.shopFeed])

  if (ready) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          overflow: "scroll",
          paddingBottom: "40px",
        }}
        className="mNoScrollBar"
      >
        <div className="container">

          <div className="row">
            <div className="col-md-12">
              <h1
                className="mSectionTitle"
              >
                {STR_SHOP[appContext.language]}
              </h1>
            </div>

            {
              (appContext.shopFeed && appContext.shopFeed.length > 0) ?
                appContext.shopFeed.map((item, i) => {
                  return (
                    <div
                      className="col-md-4"
                      key={i}
                    >
                      <OneShopItem data={item} size="small" />
                    </div>
                  )
                })
                :
                <h6
                  style={{
                    fontSize: "14px",
                  }}
                >
                  {STR_NO_ITEMS_FOUND[appContext.language]}
                </h6>
            }



          </div>

        </div>


      </div>
    )
  } else {
    return (
      <div
        className="mSupportLoading"
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        {getInlineLoader()}
      </div>
    );
  }


}