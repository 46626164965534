import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../App";
import { CDN_URL, formatMoney } from "../Helpers";

export default function View({ data }) {
  const appContext = useContext(AppContext);

  /* Place states here */


  function view() {
    appContext.navTo({
      item: "view",
      subItem: "shop-item",
      extraItem: data.id,
    });
  }

  return (
    <div
      className="OneArticle small bg-surface"
      onClick={view}
    >

      <div
        className="cover"
        style={{
          backgroundImage: `url(${CDN_URL + (data.img1 || data.img2 || data.img3)})`
        }}
      >
      </div>

      <div
        className="title font-regular"

      >
        <br />
        <span
          className="font-semi-bold mOneLineText text-secondary"
          style={{
            fontSize: "14px",

          }}
        >
          <span className="font-bold" style={{ fontSize: "22px" }}>{formatMoney(data.unitPrice)}</span> TZS/{data.unit}
        </span>
        <br />
        {data.title}
      </div>
    </div>
  );
}
