import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../App";
import { CDN_URL } from "../Helpers";
import { GrCertificate } from "react-icons/gr";

export default function View({ data, size = 'small' }) {
  const appContext = useContext(AppContext);

  /* Place states here */


  function view() {
    appContext.navTo({
      item: "view",
      subItem: "certificate-program",
      extraItem: data.id,
    });
  }

  return (
    <div
      className="OneArticle small bg-surface"
      onClick={view}
    >

      <div
        className="cover"
      >
        <GrCertificate
          style={{
            width: "100%",
            height: "100%",
          }}
          className="text-muted"
        />
      </div>

      <div
        className="title"
      >
        {data.title} <br />
        <span className="font-light">{data.description}</span>
      </div>
    </div>
  );
}
